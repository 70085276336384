import React from "react";
import "./styles.scss";
import { FaWeightHanging } from "react-icons/fa";
import {
  selectRestaurantCurrency,
  selectIsDefaultImageEnable,
} from "../../pages/main/selectors";

import { useDispatch, useSelector } from "react-redux";
import configs from "../../configs";
import i18next from "i18next";
import { useMenuSlice } from "../../pages/menuPage/actions";
import {
  calculatePriceRange,
  formatPriceRange,
} from "../../pages/menuPage/utils";
import images from "../../images";
import { useTranslation } from "react-i18next";
import sampleFoodImage from "../../images/sample-food.png";
// import { FiImage } from "react-icons/fi";
// import MarkDown from '../MarkDown';
// import queryString from 'query-string';

export default function FoodCard({ data, enableOrdering }) {
  const { actions } = useMenuSlice();
  const currency = useSelector(selectRestaurantCurrency);
  const isDefaultImageEnable = useSelector(selectIsDefaultImageEnable);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div
      className={
        !data.outOfStock ? "foodcard-wrapper" : "foodcard-wrapper sold-out"
      }
      onClick={() => dispatch(actions.setSelectedFood(data))}
    >
      <div className="image-container">
        {data.outOfStock && <span>{t("food_description.sold_out")}</span>}
        {/* <>
          {data.imageUrls[0] ? (
            <img
              src={`${configs.RESOURCES_BUCKET_URL}/${data.imageUrls[0]}`}
              alt="Food"
            />
          ) : isDefaultImageEnable ? (
            <img src={sampleFoodImage} alt="Sample" />
          ) : (
            <></>
            // <FiImage size={70} color="#9e9a9a" />
          )}
        </> */}

        <>
          {data.imageUrls.length < 1 ? (
            data?.externalPosItemId?.representation?.imageUrl ? (
              <img src={data.externalPosItemId.representation.imageUrl} alt="Food" />
            ) : data?.externalPosItemId?.representation?.color ? (
                // <div style={{ width: '30px', height: '30px', backgroundColor: data.externalPosItemId.representation.color }} />
                <></>
            ) : isDefaultImageEnable ? (
                <img
                  src={sampleFoodImage}
                  alt="Sample"
                />
            ) : (
              <></>
            )
          ) : (
            <img
              src={`${configs.RESOURCES_BUCKET_URL}/${data.imageUrls[0]}`}
              alt="Food"
            />
          )}
        </>

        <div className="properties-container">
          {data?.properties?.map((proptery, index) => {
            return <img key={index} src={images[proptery]} alt="" />;
          })}
        </div>
      </div>
      <div className="body">
        <h6>{data.name?.[i18next.language || "en"] || data.name?.["en"]}</h6>
        <div
          className="markdown"
          dangerouslySetInnerHTML={{
            __html:
              data.description?.[i18next.language || "en"] ||
              data.description?.["en"],
          }}
        ></div>

        <div className="weight" style={{marginTop:'10px'}}>
          <div>
            {data.size && (
              <>
                <FaWeightHanging />
                <p>
                  {data.size} {data.unit}
                </p>
              </>
            )}
          </div>
          <p className="value">
            {currency}
            {!data?.priceVariants?.length
              ? Number.parseFloat(data?.price).toFixed(2)
              : data?.priceVariants?.length === 1
              ? Number.parseFloat(data?.priceVariants?.[0]?.price).toFixed(2)
              : formatPriceRange(calculatePriceRange(data?.priceVariants))}
          </p>
        </div>
        <div className="price">
          <p className="value">
            {currency}{" "}
            {!data?.priceVariants?.length
              ? Number.parseFloat(data?.price).toFixed(2)
              : data?.priceVariants?.length === 1
              ? Number.parseFloat(data?.priceVariants?.[0]?.price).toFixed(2)
              : formatPriceRange(calculatePriceRange(data?.priceVariants))}
          </p>
          {enableOrdering && <p className="buy">+</p>}
        </div>
      </div>
    </div>
  );
}
